@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
    font-family: 'Bubbly';
    src: local('Bubbly'), url(./fonts/Bubbly-Regular.otf);
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Script MT Bold';
    src: local('Script MT Bold'),
        url(./fonts/Scriptbl.ttf);
    font-weight: normal;
    font-style: normal;
}


/* Modal */
.modal {
    background-color: rgba(0, 0, 0, 0.3);
}

.modal-open {
    padding-right: 0px !important;
    overflow: auto !important;
}

.modal-container {
    height: 24.375rem;
}

.modal-header {
    background-color: rgba(0, 0, 0, 0.945);
    border: none !important;
    font-size: 1rem;
    color: white;
    padding: 0 !important;
    display: block !important;

}

.modal-header .btn-close {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.modal-body {
    padding: 3px 25px 8px 25px !important;
    font-size: 0.875rem;
    background-color: rgba(0, 0, 0, 0.945);
    border: none !important;
    letter-spacing: -0.14px;
    line-height: 1.25em;
    color: #919191;
}

.modal-footer {
    background-color: rgba(0, 0, 0, 0.945);
    justify-content: flex-start !important;
    padding: 0 10px 13px 25px !important;
    color: #d10d09;
    font-style: italic;
    border: none !important;

}

.modal-infotype {
    color: white;
    font-size: 1.125rem;
    letter-spacing: -0.18;
}

.modal-content {
    height: fit-content;
    opacity: 0.9;
    border: none !important;
}

/* Back to Top button */
.scroll-button {
    background-color: transparent;
    font-size: 2rem;
    border: 1px solid #abbaba;
    border-radius: 50%;
    opacity: 0.7;
}

/* Section */

.section-title {
    text-align: left;
}

.section-title h2 {
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0;
    margin: 0 0 5px 0;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #aaaaaa;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    font-size: 1.5rem;
    background: rgba(255, 255, 255, 0.2);
    margin: 4px 10px;
}

.image-gallery-svg {
    height: 32.5px !important;
}


::-webkit-scrollbar {
    width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

@media (max-width: 768px) {
    .modal-footer {
        font-size: 14px;
    }
}

@media (min-width: 768px) {
    .modal-body {
        padding: 3px 50px 8px 50px !important;
    }

    .section-title h2 {
        margin-top: 20px;
    }

    .section-title p {
        margin-bottom: 20px;
    }

}