/* Menu */
.card-block {
    font-size: 1em;
    position: relative;
    margin: 0;
    padding: 1em;
    border: none;
    border-top: 1px solid rgba(34, 36, 38, .1);
    box-shadow: none;

}

.card {
    font-size: 1em;
    overflow: hidden;
    padding: 5;
    border: none;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    margin-top: 20px;
}

.food-card--vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;

}

@media (max-width: 768px) {
    .food-card--vertical img {
        width: 128px;
        min-height: 110px;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}

@media (min-width: 768px) {
    .food-card--vertical img {
        width: 176px;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
    }
}